<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-card-header> Billing Block Rates </b-card-header>
      <b-card-header>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          placeholder="Consumer Type"
          :options="consumerTypeOptions"
          class="w-100"
          @input="type => (consumerType = type.value)"
        />
      </b-card-header>
      <b-card-body>
        <threshold-form
          v-if="consumerType"
          :key="consumerType"
          :consumer-type="consumerType"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardBody, BCardHeader } from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, computed, ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import ThresholdForm from './ThresholdForm.vue'
import settingsStoreModule from './settingsStoreModule'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    ThresholdForm,
    vSelect,
  },
  setup() {
    const STORE_MODULE_NAME = 'app-billing-settings'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, settingsStoreModule, {
        preserveState: false,
      })
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const consumerType = ref(null)

    const consumerTypeOptions = computed(() => (store.state.app.enums
      ? _.cloneDeep(store.state.app.enums.ConsumerType)
      : []))

    return {
      consumerType,
      consumerTypeOptions,
    }
  },
}
</script>
