import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBillingThresholds(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/billing/thresholds/${payload.consumerType}`)
          .then(({ data: { results } }) => resolve(results))
          .catch(error => reject(error))
      })
    },

    updateBillingThresholds(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios.put(`/billing/thresholds/${payload.consumerType}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
