<template>
  <div
    ref="thresholdForm"
    :class="{ 'area-busy': waiting }"
  >
    <validation-observer
      ref="validationRules"
      #default="{ invalid }"
    >
      <b-form
        ref="form"
        :style="{ height: trHeight }"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <b-row
          v-for="(item, index) in thresholdItems"
          :id="index"
          :key="index"
          ref="row"
        >
          <b-col md="3">
            <validation-provider
              #default="{ errors }"
              name="Description"
              vid="Description"
              rules="required"
            >
              <b-form-group label="Description">
                <b-form-input
                  v-model="item.description"
                  type="text"
                  placeholder="for invoices"
                  maxlength="30"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="2">
            <b-form-group :label="'From (' + getValueUnit('CUBIC_METER') + ')'">
              <b-form-input
                v-model="item.min"
                type="number"
                placeholder=""
                :disabled="true"
              />
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group :label="'To (' + getValueUnit('CUBIC_METER') + ')'">
              <b-form-input
                v-model="item.threshold"
                type="number"
                placeholder=""
                @input="updateNextMinValue(index, item)"
              />
            </b-form-group>
          </b-col>

          <b-col md="2">
            <validation-provider
              #default="{ errors }"
              name="Price per cubic meter"
              vid="Price per cubic meter"
              rules="required"
            >
              <b-form-group label="Price per cubic meter (BHD)">
                <b-form-input
                  v-model="item.rate"
                  type="number"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col md="1">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-danger"
              class="btn-icon rounded-circle mt-2"
              @click="removeItem(index)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
      </b-form>
      <div class="grid grid-cols-3 gap-2 mt-4">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          :disabled="invalid"
          @click="repeateAgain"
        >
          <span>Add New</span>
        </b-button>
        <div />
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          type="submit"
          :disabled="invalid || waiting"
          @click="submitHandler"
        >
          <span v-if="waiting"><b-spinner small /> Saving...</span>
          <span v-else>Save</span>
        </b-button>
      </div>
    </validation-observer>

    <busy-modal :card="busyCard" />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import BusyModal from '@leecom/busy-modal/BusyModal.vue'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {
  ref,
  onUnmounted,
  onMounted,
  watch,
  computed,
} from '@vue/composition-api'
import store from '@/store'
import Vue from 'vue'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _ from 'lodash'
import { showErrorToast } from '@leecom/utils/support'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BSpinner,
    vSelect,
    BusyModal,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    consumerType: {
      required: true,
    },
  },
  data() {
    return {
      // validation
      required,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.thresholdItems.push({
        id: null,
        min: this.getMinValue(),
        consumer_type: this.consumerType,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    getMinValue() {
      const { length } = this.thresholdItems
      if (length < 1) {
        return 0
      }
      return this.thresholdItems[length - 1].threshold
    },
    updateNextMinValue(index, item) {
      if (this.thresholdItems[index + 1]) {
        this.thresholdItems[index + 1].min = item.threshold
      }
    },
    updateItemsMinValue() {
      this.thresholdItems.forEach((item, index) => {
        if (index < 1) {
          item.min = 0
        } else {
          item.min = this.thresholdItems[index - 1].threshold
        }
      })
    },
    loadItem(item) {
      this.thresholdItems.push(item)

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.thresholdItems.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
      this.updateItemsMinValue()
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'app-billing-settings'

    const toast = useToast()

    const busyCard = ref({
      state: null,
    })
    const waiting = ref(false)

    const thresholdForm = ref(null)

    // onMounted(() => {
    //   console.log(thresholdForm.value)
    // })

    const consumerType = ref(props.consumerType)
    const thresholdItems = ref([])
    const nextThresholdItemId = ref(1)

    const validationRules = ref(null)

    const consumerTypeOptions = computed(() => (store.state.app.enums
      ? _.cloneDeep(store.state.app.enums.ConsumerType)
      : []))

    const getValueUnit = key => _.get(
      _.find(store.state.app.enums.DisplayUnitType, { key }),
      'label',
      null,
    )

    const showSuccessTost = response => {
      const message = 'Saved Successfully.'
      toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    }

    const fetchBillingThresholds = () => new Promise((resolve, reject) => {
      busyCard.value.state = 'WAITING'

      thresholdItems.value = []
      if (thresholdForm.value) {
        thresholdForm.value.__vue__.initTrHeight()
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/fetchBillingThresholds`, {
          consumerType: consumerType.value,
        })
        .then(response => {
          resolve(response)
          thresholdThresholdItems(response)
          busyCard.value.state = null
        })
        .catch(error => {
          console.log(error)
          const { response } = error
          reject(response)
          showErrorToast(toast, response)
          busyCard.value.state = null
        })
    })

    const thresholdThresholdItems = items => {
      items.forEach((item, index) => {
        item.consumer_type_object = _.find(store.state.app.enums.ConsumerType, {
          value: item.consumer_type,
        })
        if (index < 1) {
          item.min = 0
        } else {
          item.min = items[index - 1].threshold
        }
        thresholdForm.value.__vue__.loadItem(item)
      })
    }

    fetchBillingThresholds()

    const submitHandler = () => new Promise((resolve, reject) => {
      validationRules.value.validate().then(success => {
        if (success) {
          waiting.value = true
          store
            .dispatch(`${STORE_MODULE_NAME}/updateBillingThresholds`, {
              consumerType: consumerType.value,
              items: thresholdItems.value,
            })
            .then(response => {
              waiting.value = false
              resolve(response)
              showSuccessTost(null)
              fetchBillingThresholds()
            })
            .catch(error => {
              console.log(error)
              const { response } = error
              waiting.value = false
              reject(response)
              showErrorToast(toast, response)
            })
        }
      })
    })

    return {
      thresholdForm,

      thresholdItems,
      nextThresholdItemId,

      validationRules,

      consumerTypeOptions,

      submitHandler,

      getValueUnit,

      waiting,

      busyCard,
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  transition: 0.35s height;
}
</style>
